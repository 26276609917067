<template>
	<div style="position: relative; display: flex; width: 100%; justify-content: center; min-height: 100%;">
		<vimeo-player :playerHeight="heightDinamic" v-if="renderComponent" @play="todo('play')" @pause="todo('pause')" class="playerVimeo" ref="player" :autoplay="false" :video-url="videoURL"></vimeo-player>
	</div>
</template>
<script>
import notify from "@/services/libs/notificacao";

export default {
    props:['videoURL', 'videoID', 'porcentagem', 'isMobile'],
	watch:{
		videoURL(){
			this.forceRerender();
		},
	},
	computed: {
		heightDinamic() {
			if (this.isMobile) {
				return '250'
			} else {
				return '500'
			}
		},
	},
	data() {
		return {
			renderComponent: true,
			height: 500,
			options: {
				muted: false,
                autoplay: true,
			},
			playerReady: false,
			t:''
		}
	},
	methods: {
		forceRerender () {
			this.renderComponent = false;
			
			this. $nextTick (() => {
				this.renderComponent = true;
			});
		},
		onReady() {
			this.playerReady = true
		},
		getTime(){
			let player = this.$refs.player;
			player.player.getDuration().then(function(duration) {
				let minutes = Math.floor(duration / 60);
				let seconds = duration-(minutes*60)
				let time =`${minutes} minutos e ${seconds}`;

				//console.log('vimeo - duração total convertida', time);
				//console.log('vimeo - duração total em segundos', duration);

				return duration;
			})
			player.player.getCurrentTime().then(function(duration) {
				let minutes = Math.floor(duration / 60);
				let seconds = duration-(minutes*60)
				let time =`${minutes} minutos e ${seconds}`;
				
				//console.log('vimeo - tempo atual convertido', time);
				//console.log('vimeo - tempo atual em segundos', duration);
				return duration;
			})
		},
		todo(type){
			//console.log(type);
			if(type === 'play'){
				this.$refs.player.play();
			}else if(type === 'concluiu'){
				this.$root.$emit('liberaBtn');
			}else if(type === 'calcula'){
				this.$root.$emit('calculaBtn');
			}else if(type === 'next'){
				this.$root.$emit('liberaBtnNext');
			}else{
				this.$refs.player.pause();
				//console.log('pauseee');
			}
		},
		play () {
			this.$refs.player.play();
		},
		pause () {
			this.$refs.player.pause()
		},
		getDurationTime(){
			let player = this.$refs.player;
			return player.player.getDuration().then(function(duration) {
				return duration;
			}) 
		},
		getCurrentTime(){
			let player = this.$refs.player;
			return  player.player.getCurrentTime().then(function(duration) {
				return duration;
			})
		},
		async calculaPorcentagem(type){
			var current = await this.getCurrentTime();
			var total = await this.getDurationTime();
			current = parseInt(current, 10);
			total = parseInt(total, 10);
			//console.log('função q calcula', current, total, 'porcentagem necessaria:', parseInt(this.porcentagem, 10));
			var calc = (current/total)*100;
			calc = parseInt(calc, 10);
			//console.log('resultado calculo:', calc);
			if(calc >= parseInt(this.porcentagem, 10)){
				//console.log('emite evento para liberar os btn');
				if(type === 'concluir'){
					this.todo('concluiu');
				}else if(type === 'calcula'){
					this.todo('calcula');
				}else{
					this.todo('next');
				}
			}else{
				//console.log('caiu no else ??????')
				notify('erro', this.$t('player.errors_players.msg3'));
				//console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxx vimeo')
			}
		},
        update () {
			this.$refs.player.update(this.videoURL);
			this.forceRerender();
		}
	},
	mounted(){
		this.$root.$on('clicouConcluir', data => {
			//console.log('xxxxxxxxx', data);
			if(data === 'vimeo'){
				this.calculaPorcentagem('concluir');
			}
		});
		this.$root.$on('clicouNext', data => {
			if(data === 'vimeo'){
				this.calculaPorcentagem('next');
			}
		});
		this.$root.$on('calcula', data => {
			if(data === 'vimeo'){
				this.calculaPorcentagem('calcula');
			}
		});

	},
    beforeDestroy() {
        //console.log('beforeDestroy');
    },
}
</script>

<style scoped>
.toZindex .playerVimeo {
    z-index: 99999999999999999;
}
.playerVimeo {
	min-width: 100%;
}
iframe {
	width: 100%;
	min-height: 530px !important;
}

</style>